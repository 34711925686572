<template>
  <v-container fluid>
    <v-card class="pa-5">
      <h2>{{orderItem && orderItem.cargoNumber}}</h2>
      <v-divider></v-divider>

      <!-- card status -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="8" sm="9" md="10">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.STATUS}}</p>
            <p class="body">{{orderItem && orderItem.status}}</p>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="navigateToOrderHistories" color="primary" text>
              {{$_strings.order.VIEW}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <!-- card tanggal pengambilan -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.PICKUP_DATE}}</p>
            <p class="body mb-0" v-if="orderItem && orderItem.pickupDate">{{dateFormat(orderItem.pickupDate)}} - {{timeFormat(orderItem.pickupDate)}}</p>
            <p class="body mb-0" v-if="orderItem && orderItem.baspDate">{{dateFormat(orderItem.baspDate)}} - {{timeFormat(orderItem.baspDate)}}</p>
          </v-col>
        </v-row>
      </v-card>

      <!-- card tipe kendaraan -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.TYPE_VEHICLE}}</p>
            <p class="body">{{orderItem && orderItem.transportName}}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.VEHICLE_PLATE}}</p>
            <p class="body">{{orderItem && orderItem.transportNumber || '-'}}</p>
          </v-col>
        </v-row>
      </v-card>

      <!-- card tipe lokasi asal -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="12">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.ORIGIN}}</p>
            <div class="d-flex">
              <div class="d-flex mr-2 align-center">
                <v-icon color="primary">mdi-map-marker-radius</v-icon>
              </div>
              <div>
                <p class="subtitle-1 ma-0 text--black">{{orderItem && orderItem.originalLocation}}</p>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <p class="body-2 ma-0 grey--text">{{$_strings.order.SENDER_NAME}}</p>
                <p class="body">{{orderItem && orderItem.shipments.senderName || '-'}}</p>
                <p class="body-2 ma-0 grey--text">{{$_strings.order.SENDER_NOTE}}</p>
                <pre class="overflow-auto"><p class="body">{{orderItem && orderItem.shipments.notes || '-'}}</p></pre>
              </v-col>
              <v-col cols="12" sm="6">
                <p class="body-2 ma-0 grey--text">{{$_strings.order.CONTACT_NUMBER}}</p>
                <p class="body">{{orderItem && orderItem.shipments.senderPhoneNumber || '-'}}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <!-- card tipe lokasi tujuan -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="12">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.DESTINATION}}</p>
            <div class="d-flex">
              <div class="d-flex mr-2 align-center">
                <v-icon color="yellow">mdi-map-marker-radius</v-icon>
              </div>
              <div>
                <p class="subtitle-1 ma-0 text--black">{{orderItem && orderItem.destinationLocation}}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <!-- card kategori barang -->
      <v-card class="mt-2 pa-5">
        <v-row>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.CATEGORY}}</p>
            <p class="body">{{orderItem && orderItem.shipments.category}}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="body-2 ma-0 grey--text">{{$_strings.order.SUB_CATEGORY}}</p>
            <p class="body">{{orderItem && orderItem.shipments.subCategory}}</p>
          </v-col>
        </v-row>
      </v-card>

      <!-- BASP -->
      <v-row
        class="mt-5 justify-end"
        align="end"
      >
        <v-col cols="auto" class="text-center">
          <v-btn
            depressed
            color="primary"
            min-width="200"
            @click="continueShipping"
          >
            {{ $_strings.order.CONTINUE_SHIPPING }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios';
import { dateFormat, timeFormat } from '../../helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  created() {
    const { data } = this.$route.params;
    if (!data) this.$router.replace(this.basePath);
    this.orderItem = data;
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      orderItem: {},
    };
  },
  methods: {
    basePath() {
      const patternGetBasePath = /^(\/\w*){2}/;
      const path = this.$route.path.match(patternGetBasePath)[0];
      return path;
    },
    navigateToOrderHistories() {
      const { data: { shipments } } = this.$route.params;
      this.$router.push({
        name: 'order-histories',
        params: {
          historyStatus: shipments.orderHistories,
        },
      });
    },
    continueShipping() {
      const { data: { shipmentsCargoId } } = this.$route.params;
      this.$root.$loading.show();
      const date = new Date();
      const data = {
        datetimeTz: date.toString().split('+')[1].substring(1, 1),
      };
      this.$_services.order.continueShipping(shipmentsCargoId, data, source)
        .then((res) => {
          this.$dialog.notify.success('Berhasil lanjutkan pengiriman');
          this.$router.replace(this.basePath);
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    dateFormat,
    timeFormat,
  },
};
</script>
